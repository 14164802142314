import React from 'react'
import './Portfolio.css'
import { Outlet, Link } from 'react-router-dom'

import jot1 from './images/jot1.PNG'
import jot2 from './images/jot2.PNG'
import jot3 from './images/jot3.PNG'

import sphere1 from './images/sphere1.png'
import sphere2 from './images/sphere2.png'
import sphere3 from './images/sphere3.png'

import gradient from './images/gradient1.png'



export default function Portfolio() {
  return (
    <div>
      <nav className='nav-bar'>
          
          <div className='nav-left'> 
            <Link to='/' className='link'><h2>Liam McKenna</h2></Link>
          </div>
          <div className='nav-right'>
            <Link to="/about" className='about-link link'><h2>about</h2></Link>
            <Link to="/portfolio" className='portfolio-link link'><h2>portfolio</h2></Link>

            {/* <Link to="/blog" className='blog-link link'><h2>blog</h2></Link> */}
          </div>
        </nav>
        <section className='main'>
        <div className='project'>
            <div className='left3'>
            <a className='portfolio-header' href='https://github.com/Liam-McKenna04/WDTMT'>Sphere</a>
            <p>My first project working with data science techniques, I wanted to seperate twitter followers by 'niches' or groups that follow the same group of people and tweet about the same things. This taught me a lot about using API's, graph theory, NLP, and clustering algorithms.</p>
            </div>
            <div className='right3'>
            <div className='img-holder img-w-holder'>
            <img src={sphere2} className='image-w'/>
            <img src={sphere3} className='image-w'/>

            </div>
            <img src={sphere1} className='image-w'/>

            </div>
          </div>

          <div className='project'>
            <div className='left1'>
            <a className='portfolio-header' href='https://jott.day/'>Jot | Video Journal</a>
              <p>On my gap year, I was having trouble remembering my days and sharing them with my friends so I created a video journalling platform that utilizes react native, ffmpeg, and more technologies to create an immersive video diary.</p>
            </div>
            <div className='right1'>
              <div className='img-holder'>
                <img src={jot1} className='image'/>
                <img src={jot2} className='image'/>
                <img src={jot3} className='image'/>

              </div>
            </div>
          </div>
          <div className='project'>
           
            <div className='right2'>
            <a className='portfolio-header' href='https://github.com/Liam-McKenna04/Gradient'>Project Gradient</a>
            <p>A personal deep dive into utilizing api's, automation, and text to speach api's to make a 100% automated youtube channel (before there were tons of tiktok ones). I not only utilized new technologies but also learned the difficulties of automation and important computer science thought process'. The validation run can be found here. I aditionally interviewed prominent automated channel creators and explored the ethics of automated content, a topic that will be important as AI becomes more prominent.</p>
            </div>
            <div className='left2'>
            <div className='img-holder'>
            <img src={gradient} className='image'/>

            </div>

            </div>
          </div>
        </section>

       
    </div>
  )
}
