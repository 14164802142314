
import ReactDOM from 'react-dom/client'
import { BrowserRouter, Routes, Route } from 'react-router-dom';

//pages
import About from './pages/About';
import Home from './pages/Home';
import Blog from './pages/Blog';
import Portfolio from './pages/Portfolio';
import NoPage from './pages/NoPage';
import SocialApp from './pages/SocialApp';

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
          <Route index element={<Home/>}/>
          <Route path="blog" element={<Blog/>}/>
          <Route path="about" element={<About/>}/>
          <Route path="portfolio" element={<Portfolio/>}/>
          <Route path='sat' element={<SocialApp/>}/>
          <Route path="*" element={<NoPage/>}/>
      </Routes>
    </BrowserRouter>
  );
}
const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(<App/>)


