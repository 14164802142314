import React from 'react'
import './Home.css'
import { Outlet, Link } from 'react-router-dom'
export default function Home() {
  return (
    <div className='background'>
        <nav className='nav-bar'>
           
          <div className='nav-left'> 
            <Link to='/' className='home-link link'><h2>Liam McKenna</h2></Link>
          </div>
          <div className='nav-right'>
            <Link to="/about" className='about-link link'><h2>about</h2></Link>
            <Link to="/blog" className='about-link link'><h2>blog</h2></Link>
            <Link to="/portfolio" className='portfolio-link link'><h2>portfolio</h2></Link>

            {/* <Link to="/blog" className='blog-link link'><h2>blog</h2></Link> */}
          </div>
        </nav>
        <section className='hero-section'>
          <div className='hero-div'>
            <h1 className='hero-text'>
              <span>Hi, I'm Liam. I like to </span>
              <Link to="/portfolio" className='t2'>make cool things</Link>
              <span> and </span>
              <Link to="/about" className='t4'>meet interesting people.</Link>

            </h1>

          </div>
        </section>
        <section className='portfolio-preview'>

        </section>
        <section className='blog-perview'>

        </section>
        <footer className='footer'>

        </footer>

    </div>
  )
}
