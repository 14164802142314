// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import "firebase/compat/database";
import "firebase/compat/storage";

import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAqiTzJOeBM-VXgwLijz6IPc8w1Ft0b74I",
  authDomain: "personal-website-7f110.firebaseapp.com",
  projectId: "personal-website-7f110",
  storageBucket: "personal-website-7f110.appspot.com",
  messagingSenderId: "577398479857",
  appId: "1:577398479857:web:c951e451a0067c1d850250",
  measurementId: "G-KVYZDWWYRJ"
};

// Initialize Firebase
export const app = firebase.initializeApp(firebaseConfig);
export const db = firebase.firestore();
