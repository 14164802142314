import React from 'react'
import './About.css'
import { Outlet, Link } from 'react-router-dom'
import headshot from './images/headshot5.jpg'
import twitter from './images/twitter.svg'
import linkedin from './images/linkedin.svg'
export default function About() {
  return ( 
    <div>
      <nav className='nav-bar'>
          
          <div className='nav-left'> 
            <Link to='/' className='link'><h2>Liam McKenna</h2></Link>
          </div>
          <div className='nav-right'>
            <Link to="/about" className='about-link link'><h2>about</h2></Link>
            <Link to="/portfolio" className='portfolio-link link'><h2>portfolio</h2></Link>

            {/* <Link to="/blog" className='blog-link link'><h2>blog</h2></Link> */}
          </div>
        </nav>
       
      <section className='body'>

        <div className='left-column'>
          <img className='headshot' src={headshot} alt="picture of me"></img>
          <div className='socials'>
            <a href="https://twitter.com/aertosaberß"><img src={twitter} className='social'/></a>
            <a href="https://www.linkedin.com/in/liam-mckenna-7a5002200/" ><img src={linkedin} className='social'/></a>
          </div>

        </div>
        <div className='right-column'>
        <div className='story'>
          <h2 className='abt-head'>About Me</h2>
          <p className='abt-body'>
            Hey, I'm a college freshman at Georgetown University that's passionate about 
            startups, data science, and social media. I've run gaming youtube channels and worked for a bunch of companies 
            such as Ark Data, Blackrock (briefly), and Simon Data. I've also built a few little projects, some that you can find in my portfolio
            and I'll probably be making bigger and better things as time goes on. The site's a little bare now, but that's just because I have no 
            time in college. Reach out to my email (liam.mckenna04@gmail.com) if you need anything and thanks for reading! 
          </p>
        </div>
        </div>
        
        


      </section>
      


    </div>
  )
}
