import React, { useState } from 'react'
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import {db} from './firebase'
import './SocialApp.css'

export default function SocialApp() {
  const [Input, setInput] = useState("")
  const [emailErr, setEmailErr] = useState(true)
  const inputHandler = (e) => {
    if(ValidateEmail(e.target.value) && emailErr) {
      setEmailErr(false)
    } else if (!ValidateEmail(e.target.value) && !emailErr) {
      setEmailErr(true)
    }
  
    setInput(e.target.value)
  }
  const ValidateEmail = (mail) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail))
     {
       return (true)
     }
      setEmailErr(true)
      return (false)
   }
  const submitHandler = (e) => {
    e.preventDefault();
    if (Input && ValidateEmail(Input)) {
      console.log(Input)
      //add to firebase
      db.collection("ai-sat-emails").add({
        email: Input,
        time: firebase.firestore.FieldValue.serverTimestamp()
      })
    
    }
    setInput("")

  }
  return (
    <div className='background-social'>
      <div className='main-content'>
          <h1 className='left-text'>We're building SAT studying that conforms to you for a fraction of the cost of a tutor</h1>
          {/* <h1 className='left-text'>We're building SAT prep with personalized, AI-built learning for a fraction of the cost of a private tutor</h1> */}

        <p className='right-text'>You're a little early, sign up to get notified and join the community:</p>

        <form className='form' onSubmit={submitHandler}>
          <input className={'input'} onChange={inputHandler} value={Input} placeholder='Enter your email'/>
          <button className={emailErr ? 'err-submit' :'submit-btn'} type='submit' >Subscribe</button>
        </form>
      
      </div>
    </div>
  )
}
//grow with your friends
//a way for online communities to be about more than watching videos
//meet new people who want to grow
//